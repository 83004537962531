const loadComponent = (component) => {
  return () => import(`@/views/Montebit/${component}.vue`);
};

export default [
  {
    path: "/montebit",
    redirect: "/montebit/funds",
    name: "MontebitMain",
    component: loadComponent("MainView"),
    meta: { authRequired: true },
    children: [
      {
        path: "funds",
        name: "CryptoboxFunds",
        component: loadComponent("FundsView"),
        meta: { permission: "HOME__WALLET_FUNDS" },
      },
      {
        path: "btm-daily-closures",
        name: "CryptoboxBTMDailyClosures",
        component: loadComponent("BTMDailyClosuresView"),
        meta: { permission: "BTMS__CHARTS_READ" },
      },
      {
        path: "financial-closings",
        name: "CryptoboxFinancialClosings",
        component: loadComponent("FinancialClosingsView"),
      },
      {
        path: "btm-charts",
        name: "CryptoboxBTMCharts",
        component: loadComponent("BTMChartsView"),
        meta: { permission: "BTMS__CHARTS_READ" },
      },
      {
        path: "utxos",
        name: "CryptoboxUTXOs",
        component: loadComponent("UTXOsView"),
        meta: { permission: "CMS_GET_UTXOS" },
      },
      {
        path: "operators",
        name: "CryptoboxOperators",
        component: loadComponent("OperatorView"),
        meta: { permission: "OPERATORS_GET_METHOD" },
      },
      {
        path: "roles",
        name: "CryptoboxRoles",
        component: loadComponent("RolesView"),
        meta: { permission: "OPERATOR_ROLES_GET_METHOD" },
      },
      {
        path: "legal",
        name: "Legal",
        component: loadComponent("LegalView"),
        meta: { permission: "BTM_TERMS_GET_METHOD" },
      },
    ],
  },
];
