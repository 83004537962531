import BTMDailyClosuresDTO from "./BTMDailyClosuresDTO";
import BTMDTO from "./BTMDTO";
import BTMVersionDTO from "./BTMVersionDTO"

const Dto = new Map([
  ["BTM", BTMDTO],
  ["BTMDailyClosures", BTMDailyClosuresDTO],
  ["BTMVersion", BTMVersionDTO]
]);

export default Dto;
