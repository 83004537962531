import { MAIN_SERVICE } from "@/service/index.js";

const postLoginOperator = (data) => {
  return MAIN_SERVICE.post("/operator_login", data);
};

const getOperatorSession = () => {
  return MAIN_SERVICE.get("/operators/me");
};

const postRequestOTP = (operatorData) => {
  return MAIN_SERVICE.post(`/operators/${operatorData.loginName}/request_otp`, operatorData);
}

const setOperatorPassword = (operatorData) => {
  return MAIN_SERVICE.post(`/operators/${operatorData.loginName}/set_password`, operatorData);
};

export {
  postLoginOperator,
  getOperatorSession,
  postRequestOTP,
  setOperatorPassword,
};
