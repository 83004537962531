import {
  getTermsAndNoticesOfPrivacy,
  postTermAndNoticeOfPrivacy,
  getSingleTermOrNoticeOfPrivacy,
} from "@/service/legal.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";

class LegalError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const legal = {
  state: {
    legal: [],
  },
  mutations: {
    SET_LEGAL: (state, payload) => (state.legal = payload),
  },
  actions: {
    async getTermsAndNoticesOfPrivacy({ commit }, payload) {
      try {
        let paramsString = paramsBuilder(payload.params);
        const RES = await getTermsAndNoticesOfPrivacy(paramsString);
        commit("SET_LEGAL", RES.data.data);
        return RES.data.data;
      } catch (err) {
        Sentry.captureException(
          new LegalError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getSingleTermOrNoticeOfPrivacy(_, payload) {
      try {
        const RES = await getSingleTermOrNoticeOfPrivacy(
          payload.version,
          payload.params
        );
        return RES.data.data;
      } catch (err) {
        Sentry.captureException(
          new LegalError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async postTermAndNoticeOfPrivacy(_, payload) {
      try {
        const res = await postTermAndNoticeOfPrivacy(payload);
        return res.data.data;
      } catch (err) {
        Sentry.captureException(
          new LegalError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },
  getters: {
    gTermsAndNoticesOfPrivacy(state) {
      return state.legal;
    },
  },
};

export default legal;
