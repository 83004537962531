import {
  getSessions,
  getSession,
} from "@/service/session.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import SessionDTO from "@/dto/SESSION";

class SessionError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const session = {
  state: {
    paginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    sessions: [],
  },

  mutations: {
    SET_BTMS_PAGINATION_DATA: (state, payload) => (state.paginationData = payload),
    SET_SESSIONS: (state, payload) => (state.sessions = payload),
  },

  actions: {
    async getSessions({ commit }, params) {
      try {
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getSessions(PARAMS_STRING);
        const DATA = RES.data;

        const SESSION_DTO = SessionDTO.get("Session");
        const PARSED_DATA = new SESSION_DTO(DATA.data ? DATA.data : []);
        const PARSED_SESSIONS = PARSED_DATA.Sessions
        commit("SET_BTMS_PAGINATION_DATA", DATA.meta);
        commit("SET_SESSIONS", PARSED_SESSIONS);
      } catch (err) {
        Sentry.captureException(
          new SessionError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getAllSessions(_, params) {
      try {
        params.pagination = false;
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getSessions(PARAMS_STRING);
        const DATA = RES.data.data;

        const SESSION_DTO = SessionDTO.get("Session");
        const PARSED_DATA = new SESSION_DTO(DATA ? DATA : []);
        const PARSED_SESSIONS = PARSED_DATA.Sessions
        return PARSED_SESSIONS;
      } catch (err) {
        Sentry.captureException(
          new SessionError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getSession(_, id) {
      try {
        const RES = await getSession(id);
        return RES.data.data;
      } catch (err) {
        console.log(err);
        Sentry.captureException(
          new SessionError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },

  getters: {
    gSessions(state) {
      return state.sessions;
    },

    gSessionPaginationData(state) {
      return state.paginationData;
    },
  },
};

export default session;
