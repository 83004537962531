import {
  postLoginOperator,
  getOperatorSession,
  postRequestOTP,
  setOperatorPassword,
} from "@/service/auth.api";
import VueJwtDecode from "vue-jwt-decode";
import * as Sentry from '@sentry/vue'

class AuthenticationError extends Error {
  constructor (name, errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = `${name} (${errorCode}): ${message}`
  }
}

const authentication = {
  state: {
    operatorData: null,
    operatorRoles: [],
    operatorPermissions: [],
  },

  mutations: {
    SET_OPERATOR_PERMISSIONS: (state, payload) => (state.operatorPermissions = payload),
    SET_OPERATOR_DATA: (state, payload) => (state.operatorData = payload),
    SET_OPERATOR_ROLES: (state, payload) => (state.operatorRoles = payload),
    REMOVE_ALL_OPERATOR_DATA: (state) => {
      state.operatorData = null
      state.operatorRoles = []
      state.operatorPermissions = []
    }
  },

  actions: {
    async makeLogin(_, data) {
      try {
        const OPERATOR_DATA = {
          username: data.username,
          password: data.password,
        };
        const res = await postLoginOperator(OPERATOR_DATA);
        const TOKEN = res.data.data.token;
        localStorage.setItem("op", TOKEN);
        return;
      } catch (error) {
        Sentry.captureException(new AuthenticationError(error.code, error.response.status, error.response.data.error))
        throw error.response;
      }
    },

    async getOperatorSession({ commit, dispatch }, operatorId) {
      try {
        const res = await getOperatorSession(operatorId);
        const DATA = res.data.data;
        const ROLES = DATA.roles;
        commit("SET_OPERATOR_DATA", DATA);
        commit("SET_OPERATOR_ROLES", ROLES);
        dispatch("parseOperatorPermissions")
        Sentry.setUser({ username: DATA.loginName })
      } catch (error) {
        Sentry.captureException(new AuthenticationError(error.code, error.response.status, error.response.data.error))
        throw error.response;
      }
    },

    parseOperatorPermissions({ state, commit }) {
      const PERMISSIONS = [];

      for (let i = 0; i < state.operatorRoles.length; i++) {
        const ROLE = state.operatorRoles[i];

        for (let j = 0; j < ROLE.permissions.length; j++) {
          const PERMISSION = ROLE.permissions[j];

          if (!PERMISSIONS.includes(ROLE.permissions[j])) {
            PERMISSIONS.push(PERMISSION);
          }
        }
      }

      commit("SET_OPERATOR_PERMISSIONS", PERMISSIONS)
    },

    async requestOTP(_, data) {
      try {
        await postRequestOTP(data);
      } catch (error) {
        Sentry.captureException(new AuthenticationError(error.code, error.response.status, error.response.data.error))
        throw error.response;
      }
    },

    async operatorPasswordAssignment(_, data) {
      try {
        await setOperatorPassword(data);
      } catch (error) {
        Sentry.captureException(new AuthenticationError(error.code, error.response.status, error.response.data.error))
        throw error.response;
      }
    },

    logOut({ commit }) {
      localStorage.removeItem("op");
      commit("REMOVE_ALL_OPERATOR_DATA");
    },
  },

  getters: {
    gOperatorData: (state) => {
      return state.operatorData;
    },

    gTokenData() {
      const TOKEN = localStorage.getItem("op");
      const DECODED = VueJwtDecode.decode(TOKEN);
      return DECODED;
    },

    gOperatorPermissions(state) {
      return state.operatorPermissions
    },
  },
};

export default authentication;
