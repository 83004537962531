import { MAIN_SERVICE } from "@/service/index.js";

const getBTMVersions = (params) => {
  const route = `/btm_apps${params}`;
  return MAIN_SERVICE.get(route);
};

const getBTMVersion = (id) => {
  const route = `/btm_apps/${id}`;
  return MAIN_SERVICE.get(route);
};

const postBTMVersion = (body) => {
  const form = new FormData();
  form.append('version', body.version);
  form.append('changeLog', body.changeLog);
  form.append('file', body.file);

  const route = `/btm_apps`;
  return MAIN_SERVICE.post(route, form, { headers: { "Content-Type": "multipart/form-data" } });
};

const putBTMVersion = (id, body) => {
  const route = `/btm_apps/${id}`;
  return MAIN_SERVICE.put(route, body);
};

const deleteBTMVersion = (id) => {
  const route = `/btm_apps/${id}`;
  return MAIN_SERVICE.delete(route);
};

export {
  getBTMVersions,
  getBTMVersion,
  postBTMVersion,
  putBTMVersion,
  deleteBTMVersion,
};
