import { Loader } from '@googlemaps/js-api-loader';

const btm = {
  state: {
    google: null,
  },

  mutations: {
    SET_GOOGLE_API: (state, payload) => state.google = payload,
  },

  actions: {
    async setGoogleMapsApi({ commit }) {
      const VUE_APP_GOOGLE_MAPS_AKI_KEY = "AIzaSyBdBrD3IHjQw-P9W7tss6-UFmrlV7VQq_k";
      const LOADER = new Loader({
        apiKey: VUE_APP_GOOGLE_MAPS_AKI_KEY,
        version: "weekly",
      })

      LOADER.load().then(google => {
        commit("SET_GOOGLE_API", google);
      })
    },
  },

  getters: {
    gGoogleApi(state){
      return state.google
    },
  },
};

export default btm;
