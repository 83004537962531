import { MAIN_SERVICE } from "@/service/index.js";

const getOperators = (params) => {
  const route = `/operators${params}`
  return MAIN_SERVICE.get(route)
}

const getOperator = (loginName) => {
  const route = `/operators/${loginName}`
  return MAIN_SERVICE.get(route)
}

const postOperator = (body) => {
  const route = `/operators`
  return MAIN_SERVICE.post(route, body)
}

const putOperator = (loginName, body) => {
  const route = `/operators/${loginName}`
  return MAIN_SERVICE.put(route, body)
}

const postOperatorPasswordRequest = (loginName, body) => {
  const route = `/operators/${loginName}/reset_password`
  return MAIN_SERVICE.post(route, body)
}

export { getOperators, getOperator, postOperator, putOperator, postOperatorPasswordRequest };
