import {
  getFinancialClosings,
  downloadFinancialClosings,
} from "@/service/financial-closing.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import FinancialClosingsDTO from "@/dto/FINANCIAL_CLOSING";

class FinancialClosings extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const financialClosing = {
  state: {
    paginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    financialClosings: [],
  },

  mutations: {
    SET_BTMS_PAGINATION_DATA: (state, payload) => (state.paginationData = payload),
    SET_FINANCIAL_CLOSINGS: (state, payload) => (state.financialClosings = payload),
  },

  actions: {
    async getFinancialClosings({ commit }, payload) {
      try {
        commit("SET_FINANCIAL_CLOSINGS", []);

        const PARAMS_STRING = paramsBuilder(payload.params);
        const RES = await getFinancialClosings(payload.pathVariable, PARAMS_STRING);
        const DATA = RES.data;

        const financialClosingsDTO = FinancialClosingsDTO.get("FinancialClosings");
        const PARSED_DATA = new financialClosingsDTO(DATA.data ? DATA.data : []);
        commit("SET_BTMS_PAGINATION_DATA", DATA.meta);
        commit("SET_FINANCIAL_CLOSINGS", PARSED_DATA.FinancialClosings);
      } catch (err) {
        Sentry.captureException(
          new FinancialClosings(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async downloadFinancialClosings(_, payload) {
      try {
        const PARAMS_STRING = paramsBuilder(payload.params);
        const RES = await downloadFinancialClosings(payload.pathVariable, PARAMS_STRING);
        return RES.data
      } catch (err) {
        Sentry.captureException(
          new FinancialClosings(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },

  getters: {
    gFinancialClosings(state) {
      return state.financialClosings;
    },

    gFinancialClosingsPaginationData(state) {
      return state.paginationData;
    },
  },
};

export default financialClosing;
