import { MAIN_SERVICE } from "@/service/index.js";

const getOperations = (params) => {
  const route = `/btmtx${params}`;
  return MAIN_SERVICE.get(route);
};

const getOperationsSummary = (params) => {
  const route = `/btms/transactions_summary${params}`;
  return MAIN_SERVICE.get(route);
};

export {
  getOperations,
  getOperationsSummary,
};
