const loadComponent = (component) => {
  return () => import(`@/views/Rayyo/${component}.vue`);
};

export default [
  {
    path: "/rayyo",
    redirect: "/rayyo/home",
    name: "RayyoMain",
    component: loadComponent("MainView"),
    meta: { authRequired: true },
    children: [
      {
        path: "home",
        name: "RayyoHome",
        component: loadComponent("HomeView"),
      },
      {
        path: "users",
        name: "RayyoUsers",
        component: loadComponent("UsersView"),
        meta: { permission: "RAYYO_USERS_LIST_READ" },
      },
      {
        path: "users/:id",
        name: "RayyoUserDetail",
        component: loadComponent("UserDetailView"),
        meta: { permission: "RAYYO_USERS_LIST_READ" },
      },
      {
        path: "funnel",
        name: "RayyoFunnel",
        component: loadComponent("FunnelView"),
      },
      {
        path: "transactions",
        name: "RayyoTransactions",
        component: loadComponent("TransactionsView"),
        meta: { permission: "CMS_GET_WAL_TXS" },
      },
      // {
      //   path: "pld-reports",
      //   name: "RayyoPLDReports",
      //   component: loadComponent("PLDReportsView"),
      //   meta: { permission: "TBDDDDDDD" },
      // },
    ],
  },
];
