import { MAIN_SERVICE } from "@/service/index.js";

const getTermsAndNoticesOfPrivacy = (params) => {
  const route = `/btm_terms${params}`;
  return MAIN_SERVICE.get(route);
};

const getSingleTermOrNoticeOfPrivacy = (version, params) => {
  const route = `/btm_terms/${version}${params}`;
  return MAIN_SERVICE.get(route);
};

const postTermAndNoticeOfPrivacy = (body) => {
  const route = "/btm_terms";
  return MAIN_SERVICE.post(route, body);
};

export {
  getTermsAndNoticesOfPrivacy,
  postTermAndNoticeOfPrivacy,
  getSingleTermOrNoticeOfPrivacy,
};
