import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueHead from "vue-head";
import store from "./store";
import Vuelidate from "vuelidate";
import "./scss/index.scss";
import "./plugins/sentry";

Vue.use(VueHead);
Vue.use(Vuelidate);

Vue.prototype.$sleep = ms => new Promise(resolve => setTimeout(() => resolve(), ms));

Vue.config.productionTip = !process.env.NODE_ENV === "development";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
