class BTMDTO {
  constructor(BTMs) {
    this.BTMs = BTMs.map((btmData) => {
      return {
        id: btmData.id,
        slug: btmData.slug,
        description: btmData.description,
        limitNoKyc: btmData.limitNoKyc,
        limitWithKyc: btmData.limitWithKyc,
        address: btmData.address,
        latitude: btmData.latitude,
        longitude: btmData.longitude,
        active: btmData.active,
        createdAt: btmData.createdAt,
        updatedAt: btmData.updatedAt,
        model: btmData.model,
        bidirectional: btmData.bidirectional,
        hours: btmData.hours,
        mapUrl: btmData.mapUrl,
        operatingSince: btmData.operatingSince,
        imageUrl: btmData.imageUrl,
        externalId: btmData.externalId,
        zoneId: btmData.zone.id,
        zoneDescription: btmData.zone.description,
        buyMultiplier: btmData.buyMultiplier,
        withdrawMultiplier: btmData.withdrawMultiplier,
        contactEmail: btmData.contactEmail,
        contactPhone: btmData.contactPhone,
        outOfService: btmData.outOfService,
        reboot: btmData.reboot,
        lastConnection: btmData.lastConnection,
      };
    });
  }
}

export default BTMDTO;
