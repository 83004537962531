export function satoshisToBTC(amount) {
  if (!amount) return "0.00000000";
  return `${(Math.abs(amount) / 100000000).toLocaleString(undefined, {
    minimumFractionDigits: 8,
    maximumFractionDigits: 20,
  })}`;
}

export function formattedSatoshisToBTC(amount) {
  if (!amount) return "₿0";
  if (amount < 0) {
    return `-₿${(Math.abs(amount) / 100000000).toLocaleString(undefined, {
      minimumFractionDigits: 8,
      maximumFractionDigits: 20,
    })}`;
  } else {
    return `₿${(amount / 100000000).toLocaleString(undefined, {
      minimumFractionDigits: 8,
      maximumFractionDigits: 20,
    })}`;
  }
}

export function centsToPesos(amount) {
  if (!amount) return 0;
  return (Math.round(amount) / 100);
}

export function formattedCentsToPesos(amount) {
  if (!amount) return "$0";
  const AMOUNT_IN_PESOS = centsToPesos(amount)
  const FORMATTER = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: Number.isInteger(AMOUNT_IN_PESOS) ? "0" : "2",
  });
  return `${FORMATTER.format(AMOUNT_IN_PESOS)}`;
}

export function formattedPesos(amount) {
  if (!amount) return "$0";
  const FORMATTER = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: Number.isInteger(amount) ? "0" : "2",
  });
  return `${FORMATTER.format(amount)}`;
}

export function satoshisToPesos(price, amount) {
  if (!amount) return 0;
  const AMOUNT_IN_CENTS = (amount * price) / 100000000
  const AMOUNT_IN_PESOS = Math.round(AMOUNT_IN_CENTS) / 100
  return (AMOUNT_IN_PESOS);
}

export function formattedSatoshisToPesos(price, amount) {
  if (!amount) return "$0";
  const AMOUNT_IN_SATOSHIS = satoshisToPesos(price, amount)
  const FORMATTER = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: Number.isInteger(AMOUNT_IN_SATOSHIS) ? "0" : "2",
  });
  return `${FORMATTER.format(AMOUNT_IN_SATOSHIS)}`;
}
