import { MAIN_SERVICE } from "@/service/index.js";

const getPermissions = () => {
  const ROUTE = "/operator_permissions?pagination=false"
  return MAIN_SERVICE.get(ROUTE);
}

const getRoles = (params) => {
  const ROUTE = `/operator_roles${params}`
  return MAIN_SERVICE.get(ROUTE);
};

const postRole = (body) => {
  const ROUTE = `/operator_roles`
  return MAIN_SERVICE.post(ROUTE, body);
}

const putRole = (id, body) => {
  const ROUTE = `/operator_roles/${id}`
  return MAIN_SERVICE.put(ROUTE, body);
}

export { getPermissions, getRoles, postRole, putRole };
