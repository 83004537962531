import {
  getOperations,
  getOperationsSummary,
} from "@/service/operation.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import OperationDto from "@/dto/OPERATION";

class OperationError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const operationsBTMSlugAssigner = (operations, btms) => {
  return operations.map(t => {
    t.btmSlug = btms.find(b => b.id === t.btmId).slug;
    return t;
  });
};

const operationsSummaryParser = (operationsSummaryList) => {
  const SUMMARY = {
    sale: {
      total: 0,
      pending: 0,
      error: 0,
      confirmed: 0,
    },
    withdrawal: {
      total: 0,
      pending: 0,
      error: 0,
      redeemed: 0,
      notRedeemed: 0,
    },
  };

  operationsSummaryList.forEach((ts) => {
    ts.stats.forEach((stat) => {
      if (stat.sale) {
        SUMMARY.sale.pending += stat.sale.pending;
        SUMMARY.sale.error += stat.sale.error;
        SUMMARY.sale.confirmed += stat.sale.confirmed;
        SUMMARY.sale.total +=
          stat.sale.pending + stat.sale.error + stat.sale.confirmed;
      }

      if (stat.withdrawal) {
        SUMMARY.withdrawal.pending += stat.withdrawal.pending;
        SUMMARY.withdrawal.error += stat.withdrawal.error;
        SUMMARY.withdrawal.redeemed += stat.withdrawal.redeemed;
        SUMMARY.withdrawal.notRedeemed += stat.withdrawal.notRedeemed;
        SUMMARY.withdrawal.total +=
          stat.withdrawal.pending +
          stat.withdrawal.error +
          stat.withdrawal.redeemed +
          stat.withdrawal.notRedeemed;
      }
    });
  });

  return SUMMARY;
};

const btm = {
  state: {
    operationsPaginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    operations: [],
    operationsSummary: {
      sale: {
        total: null,
        pending: null,
        error: null,
        confirmed: null,
      },
      withdrawal: {
        total: null,
        pending: null,
        error: null,
        redeemed: null,
        notRedeemed: null,
      },
    },
  },

  mutations: {
    SET_OPERATIONS_PAGINATION_DATA: (state, payload) =>
      (state.operationsPaginationData = payload),
    SET_OPERATIONS: (state, payload) =>
      (state.operations = payload),
    SET_SALE_SUMMARY: (state, payload) =>
      (state.operationsSummary.sale = payload),
    SET_WITHDRAWAL_SUMMARY: (state, payload) =>
      (state.operationsSummary.withdrawal = payload),
  },

  actions: {
    async getOperations({ commit, getters }, params) {
      try {
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getOperations(PARAMS_STRING);
        const DATA = RES.data;
        const operationsDTO = OperationDto.get("Operations");
        const PARSED_DATA = new operationsDTO(DATA.data ? DATA.data : []);
        const PARSED_TRANSACTIONS = operationsBTMSlugAssigner(PARSED_DATA.Operations, getters.gBTMs);
        commit("SET_OPERATIONS_PAGINATION_DATA", DATA.meta);
        commit("SET_OPERATIONS", PARSED_TRANSACTIONS);
      } catch (err) {
        Sentry.captureException(
          new OperationError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getAllOperations({ getters }, params) {
      try {
        params.pagination = false
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getOperations(PARAMS_STRING);
        const DATA = RES.data.data;
        const operationsDTO = OperationDto.get("Operations");
        const PARSED_DATA = new operationsDTO(DATA ? DATA : []);
        const PARSED_TRANSACTIONS = operationsBTMSlugAssigner(PARSED_DATA.Operations, getters.gBTMs);
        return PARSED_TRANSACTIONS;
      } catch (err) {
        Sentry.captureException(
          new OperationError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getOperationsSummary({ commit }, payload) {
      try {
        let paramsString = paramsBuilder(payload.params);
        const DATA = await getOperationsSummary(paramsString);
        const PARSED_SUMMARY = operationsSummaryParser(DATA.data.data);
        if (payload.setType === "both") {
          commit("SET_SALE_SUMMARY", PARSED_SUMMARY.sale);
          commit("SET_WITHDRAWAL_SUMMARY", PARSED_SUMMARY.withdrawal);
        } else if (payload.setType === "sale") {
          commit("SET_SALE_SUMMARY", PARSED_SUMMARY.sale);
        } else if (payload.setType === "withdrawal") {
          commit("SET_WITHDRAWAL_SUMMARY", PARSED_SUMMARY.withdrawal);
        }
      } catch (err) {
        Sentry.captureException(
          new OperationError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },

  getters: {
    gOperations(state) {
      return state.operations;
    },

    gOperationsPaginationData(state) {
      return state.operationsPaginationData;
    },

    gOperationsSummary(state) {
      return state.operationsSummary;
    },
  },
};

export default btm;
