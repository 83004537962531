import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_URL;

const HEADERS = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("op")}`,
};

const MAIN_SERVICE = axios.create({
  baseURL: BASE_URL,
  headers: HEADERS,
});

MAIN_SERVICE.interceptors.request.use(
  function (config) {
    const key = localStorage.getItem("op");
    if (key != undefined) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem("op")}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { MAIN_SERVICE };
