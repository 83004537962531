import { getUsers, getUser, getUsersTransactions } from "@/service/user.api";
import paramsBuilder from "@/helpers/params-builder";
import UserDto from "@/dto/USER";
import * as Sentry from "@sentry/vue";

class UserError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const transactionsParser = (transactions) => {
  let parsedTransactions = transactions.map((t) => {
    // if (t.txType === "R" || t.txType === "C" || t.txType === "I" || t.txType === "W") {
    //   t.type = "RECIBIDO"
    // } else {
    //   t.type = "ENVIADO"
    // }
    // COMMENT: Este bloque se agrega para solucionar error de cantidades con valores negativos/positivo erróneos de la API
    if (t.amount >= 0) {
      t.type = "RECIBIDO";
    } else {
      t.type = "ENVIADO";
    }

    if (t.sourceTxTable === "transfers") {
      t.status = "CONFIRMADA";
    } else if (t.sourceTxTable === "btc_tx") {
      if (t.txDetails) {
        t.status = t.txDetails.height !== 0 ? "CONFIRMADA" : "PENDIENTE";
      } else {
        t.status = "CONFIRMADA";
      }
    }

    if (t.sourceTxTable === "btc_tx") {
      t.protocol = "ON CHAIN";
    } else if (t.sourceTxTable === "transfers") {
      t.protocol = "TRANSFER";
    } else if (t.sourceTxTable === "stp_tx") {
      t.protocol = "STP";
    }

    t.btcAmount = t.amount;
    t.mxnAmount = null;
    t.conversion = null;

    if (t.txDetails) {
      if (t.sourceTxTable === "btc_tx" || t.sourceTxTable === "transfers") {
        t.mxnAmount = null;
        if (t.sourceTxTable.marketPrice) {
          t.conversion =
            (t.btcAmount / 100000000) * (t.sourceTxTable.marketPrice / 100);
        }
      } else if (
        t.sourceTxTable === "stp_tx" ||
        t.sourceTxTable === "exchange_tx"
      ) {
        t.mxnAmount = t.sourceTxTable.mxnAmount;
      }
    }

    delete t.txType;
    delete t.refer;
    delete t.notificationId;
    delete t.memo;
    delete t.sourceTx;
    delete t.sourceTxTable;
    delete t.txDetails;
    return t;
  });
  return parsedTransactions;
};

const btm = {
  state: {
    paginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    users: [],
    usersTransactions: [],
  },

  mutations: {
    SET_USERS_PAGINATION_DATA: (state, payload) => (state.paginationData = payload),
    SET_USERS: (state, payload) => (state.users = payload),
    SET_TRANSACTIONS_USERS: (state, payload) =>
      (state.usersTransactions = payload),
  },

  actions: {
    async getUsers({ commit }, params) {
      try {
        let paramsString = paramsBuilder(params);
        const res = await getUsers(paramsString);
        const DATA = res.data;
        const USER_DTO = UserDto.get("Users")
        const PARSED_DATA = new USER_DTO(DATA.data ? DATA.data : []);
        commit("SET_USERS_PAGINATION_DATA", DATA.meta);
        commit("SET_USERS", PARSED_DATA.Users);
      } catch (err) {
        Sentry.captureException(
          new UserError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getAllUsers(_, params) {
      try {
        params.pagination = false
        let paramsString = paramsBuilder(params);
        const res = await getUsers(paramsString);
        const DATA = res.data;
        const USER_DTO = UserDto.get("Users")
        const PARSED_DATA = new USER_DTO(DATA.data ? DATA.data : []);
        return PARSED_DATA.Users;
      } catch (err) {
        Sentry.captureException(
          new UserError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getUser(_, userId) {
      try {
        const res = await getUser(userId);
        return res.data;
      } catch (err) {
        Sentry.captureException(
          new UserError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getUsersTransactions({ commit }, params) {
      try {
        const paramsString = paramsBuilder(params);
        const res = await getUsersTransactions(paramsString);
        const DATA = res.data;
        const PARSED_TRANSACTIONS = transactionsParser(DATA.data);
        commit("SET_USERS_PAGINATION_DATA", DATA.meta);
        commit("SET_TRANSACTIONS_USERS", PARSED_TRANSACTIONS);
      } catch (err) {
        console.log(err);
        Sentry.captureException(
          new UserError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getAllUsersTransactions(_, params) {
      try {
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getUsersTransactions(PARAMS_STRING);
        return RES.data.data;
      } catch (err) {
        console.log(err);
        Sentry.captureException(
          new UserError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },

  getters: {
    gUsers(state) {
      return state.users;
    },
    gUsersPaginationData(state) {
      return state.paginationData;
    },
  },
};

export default btm;
