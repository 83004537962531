import { getBTMEarningsChartData, getFundsChartData, getNewUsersChartData } from "@/service/chart.api";
import paramsBuilder from "@/helpers/params-builder"
import * as Sentry from '@sentry/vue'

class ChartError extends Error {
  constructor(name, errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = `${name} (${errorCode}): ${message}`
  }
}

const chart = {
  actions: {
    async getBTMEarningsChartData(_, paramsObject) {
      try {
        let paramsString = paramsBuilder(paramsObject)
        const res = await getBTMEarningsChartData(paramsString);
        return res.data.data
      } catch (err) {
        console.log(err)
        Sentry.captureException(new ChartError(err.code, err.response.status, err.response.data.error))
        throw err.response;
      }
    },
    
    async getFundsChartData(_, paramsObject) {
      try {
        let paramsString = paramsBuilder(paramsObject)
        const res = await getFundsChartData(paramsString)
        return res.data.data
      } catch (err) {
        console.log(err)
        Sentry.captureException(new ChartError(err.code, err.response.status, err.response.data.error))
        throw err.response
      }
    },

    async getNewUsersChartData(_, paramsObject) {
      try {
        let paramsString = paramsBuilder(paramsObject)
        const res = await getNewUsersChartData(paramsString)
        return res.data.data
      } catch (err) {
        console.log(err)
        Sentry.captureException(new ChartError(err.code, err.response.status, err.response.data.error))
        throw err.response
      }
    }
  },
};

export default chart;
