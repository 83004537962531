import Vue from "vue"; 
import router from "@/router";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://92605baba0e64304929fc63c2f190c45@o4504452674158592.ingest.sentry.io/4504452694212608",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        stripTracingHeader: true,
        // tracePropagationTargets: ["localhost", "rayyo.io", /^\//],
      }),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
  });
}
