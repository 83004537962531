class FinancialClosingsDTO {
  constructor(FinancialClosings) {
    this.FinancialClosings = FinancialClosings.map((operation) => {
      return {
        btmId: operation.btmId,
        date: operation.date,
        initialBalance: operation.initialBalance,
        entries: operation.entries,
        exits: operation.exits,
        finalBalance: operation.finalBalance,
      };
    });
  }
}


export default FinancialClosingsDTO;
