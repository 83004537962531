import {
  getWalletBalances,
  getFunds,
  getWalletsFunds,
  withdrawBTC,
  withdrawMXN,
  transfer,
  createNewAddress,
  getTotalWalletFunds,
} from "@/service/funds.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import FundsDto from "@/dto/FUND";

class FundsError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const funds = {
  state: {
    paginationData: {
      page: 1,
      itemsPerPage: 5,
      totalItems: 0,
    },
    funds: [],
    totalFunds: null,
  },

  mutations: {
    SET_FUNDS: (state, payload) => (state.funds = payload),
    SET_FUNDS_PAGINATION_DATA: (state, payload) =>
      (state.paginationData = payload),
    SET_TOTAL_FUNDS: (state, payload) => (state.totalFunds = payload),
  },

  actions: {
    async getWalletBalances(_, currency) {
      try {
        const RES = await getWalletBalances(currency)
        return RES.data.data
      } catch(err) {
        Sentry.captureException(
          new FundsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    // COMMENT: TO BE DELETED
    async getFunds({ commit }, payload) {
      try {
        const paramsString = paramsBuilder(payload.params);
        const DATA = await getFunds(paramsString);
        const { meta } = DATA.data;
        const FundsDTO = FundsDto.get("Funds");
        const parsedData = new FundsDTO(DATA.data.data ? DATA.data.data : []);
        commit("SET_FUNDS", parsedData.Funds);
        commit("SET_FUNDS_PAGINATION_DATA", {
          page: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
        });
      } catch (err) {
        Sentry.captureException(
          new FundsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getWalletsFunds({ commit }, payload) {
      try {
        const paramsString = paramsBuilder(payload.params);
        const DATA = await getWalletsFunds(payload.currency, paramsString);
        const { meta } = DATA.data;
        const WalletsFundsDTO = FundsDto.get("WalletsFunds");
        const parsedData = new WalletsFundsDTO(DATA.data.data ? DATA.data.data : [], payload.currency);
        commit("SET_FUNDS", parsedData.WalletsFunds);
        commit("SET_FUNDS_PAGINATION_DATA", {
          page: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
        });
      } catch (err) {
        Sentry.captureException(
          new FundsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async withdrawBTC(_, body) {
      try {
        return await withdrawBTC(body);
      } catch (err) {
        Sentry.captureException(
          new FundsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async withdrawMXN(_, body) {
      try {
        return await withdrawMXN(body);
      } catch (err) {
        Sentry.captureException(
          new FundsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async transfer(_, body) {
      try {
        return await transfer(body);
      } catch (err) {
        Sentry.captureException(
          new FundsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async createNewAddress() {
      try {
        return await createNewAddress();
      } catch (err) {
        Sentry.captureException(
          new FundsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
    
    async getTotalWalletFunds({ commit }) {
      try {
        const RES = await getTotalWalletFunds("");
        commit("SET_TOTAL_FUNDS", RES.data.data);
      } catch (err) {
        Sentry.captureException(
          new FundsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },
  
  getters: {
    gFundsPaginationData(state) {
      return state.paginationData;
    },

    gFunds(state) {
      return state.funds;
    }
  },
};

export default funds;
