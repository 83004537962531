import { getUTXOs } from "@/service/utxos.api";
import paramsBuilder from "@/helpers/params-builder";
import UTXODto from "@/dto/UTXO";
import * as Sentry from "@sentry/vue";

class UTXOError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const utxo = {
  state: {
    paginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    summary: {
      total: 0,
      totalAvailable: 0,
      totalDust: 0,
    },
    UTXOs: [],
  },

  mutations: {
    SET_UTXOS_PAGINATION_DATA: (state, payload) => (state.paginationData = payload),
    SET_UTXOS: (state, payload) => (state.UTXOs = payload),
    SET_SUMMARY: (state, payload) => (state.summary = payload),
  },

  /*
  "total": 1265404822840,
    "total_avail": 1265404816872,
    "total_dust": 5968,
  */

  actions: {
    async getUTXOs({ commit }, params) {
      try {
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getUTXOs(PARAMS_STRING);
        const DATA = RES.data;
        const UTXO_DTO = UTXODto.get("UTXO")
        const PARSED_DATA = new UTXO_DTO(DATA.Utxos ? DATA.Utxos : []);
        commit("SET_UTXOS_PAGINATION_DATA", DATA.Meta);
        commit("SET_UTXOS", PARSED_DATA.UTXOs);
        const SUMMARY = {
          total: DATA.total,
          totalAvailable: DATA.total_avail,
          totalDust: DATA.total_dust,
        }
        commit("SET_SUMMARY", SUMMARY)
      } catch (err) {
        Sentry.captureException(
          new UTXOError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getAllUTXOs(_, params) {
      try {
        params.pagination = false
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getUTXOs(PARAMS_STRING);
        const DATA = RES.data;

        const UTXO_DTO = UTXODto.get("UTXO");
        const PARSED_BTMS = new UTXO_DTO(DATA.Utxos ? DATA.Utxos : []);
        return PARSED_BTMS.UTXOs;
      } catch (err) {
        Sentry.captureException(
          new UTXOError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },

  getters: {
    gUTXOs(state) {
      return state.UTXOs;
    },
    gUTXOsSumary(state) {
      return state.summary;
    },
    gUTXOsPaginationData(state) {
      return state.paginationData;
    },
  },
};

export default utxo;
