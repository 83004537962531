import {
  getOperators,
  getOperator,
  postOperator,
  putOperator,
  postOperatorPasswordRequest,
} from "@/service/operator.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import OperatorDto from "@/dto/OPERATOR";

class OperatorsError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const operators = {
  state: {
    paginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    operators: [],
  },

  mutations: {
    SET_OPERATORS_PAGINATION_DATA: (state, payload) => (state.paginationData = payload),
    SET_OPERATORS: (state, payload) => (state.operators = payload),
  },

  actions: {
    async getOperators({ commit }, paramsObject) {
      try {
        let paramsString = paramsBuilder(paramsObject);
        const RES = await getOperators(paramsString);
        const DATA = RES.data;
        const OperatorsDTO = OperatorDto.get("Operators");
        const OPERATORS = new OperatorsDTO(DATA.data ? DATA.data : []);
        commit("SET_OPERATORS_PAGINATION_DATA", DATA.meta);
        commit("SET_OPERATORS", OPERATORS.Operators);
      } catch (err) {
        Sentry.captureException(
          new OperatorsError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },

    async getAllOperators(_, params) {
      try {
        params.pagination = false;
        let paramsString = paramsBuilder(params);
        const res = await getOperators(paramsString);
        return res.data.data;
      } catch (err) {
        Sentry.captureException(
          new OperatorsError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },

    async getOperator(_, loginName) {
      try {
        const RES = await getOperator(loginName);
        return RES;
      } catch (err) {
        Sentry.captureException(
          new OperatorsError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },

    async postOperator(_, operatorData) {
      try {
        await postOperator(operatorData);
        return;
      } catch (err) {
        Sentry.captureException(
          new OperatorsError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },

    async putOperator(_, operatorData) {
      try {
        await putOperator(operatorData.loginName, operatorData);
        return;
      } catch (err) {
        Sentry.captureException(
          new OperatorsError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },

    async postOperatorPasswordRequest(_, operatorData) {
      try {
        await postOperatorPasswordRequest(operatorData.loginName, operatorData);
        return;
      } catch (err) {
        Sentry.captureException(
          new OperatorsError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },
  },

  getters: {
    gOperators(state) {
      return state.operators;
    },

    gOperatorPaginationData(state) {
      return state.paginationData;
    },
  },
};

export default operators;
