import { MAIN_SERVICE } from "@/service/index.js";

const getBTMs = (params) => {
  const route = `/btm${params}`;
  return MAIN_SERVICE.get(route);
};

const getBTM = (id) => {
  const route = `/btm/${id}`;
  return MAIN_SERVICE.get(route);
};

const postBTM = (body) => {
  const route = `/btm`;
  return MAIN_SERVICE.post(route, body);
};

const putBTM = (id, body) => {
  const route = `/btm/${id}`;
  return MAIN_SERVICE.put(route, body);
};

const getBTMZones = () => {
  const route = "/btm_zones";
  return MAIN_SERVICE.get(route);
};

const getBTMBalances = (params) => {
  const route = `/btm_note_balances${params}`;
  return MAIN_SERVICE.get(route);
};

const postBTMRegistrationCode = (body) => {
  const route = "/btms/prepare_registration";
  return MAIN_SERVICE.post(route, body);
};

const putBTMBulkActions = (body) => {
  const route = "/btm";
  return MAIN_SERVICE.put(route, body);
};

const getBTMVideos = (params) => {
  const route = `/app-files${params}`;
  return MAIN_SERVICE.get(route);
};

const getBTMLogs = (params) => {
  const route = `/btm-logs${params}`;
  return MAIN_SERVICE.get(route);
};

const getBTMsDailyClosures = (params) => {
  const route = `/btm_note_balances/reports${params}`;
  return MAIN_SERVICE.get(route);
};

export {
  getBTMs,
  getBTM,
  postBTM,
  putBTM,
  getBTMZones,
  getBTMBalances,
  postBTMRegistrationCode,
  putBTMBulkActions,
  getBTMVideos,
  getBTMLogs,
  getBTMsDailyClosures,
};
