const loadComponent = (component) => {
  return () => import(`@/views/Cryptobox/${component}.vue`);
};

export default [
  {
    path: "/cryptobox",
    redirect: "/cryptobox/home",
    name: "CryptoboxMain",
    component: loadComponent("MainView"),
    meta: { authRequired: true },
    children: [
      {
        path: "home",
        name: "CryptoboxHome",
        component: loadComponent("HomeView"),
      },
      {
        path: "btms",
        name: "CryptoboxBTMs",
        component: loadComponent("BTMsView"),
        meta: { permission: "BTM_GET_METHOD" },
      },
      {
        path: "btms/:id",
        name: "CryptoboxBTMDetail",
        component: loadComponent("BTMDetailView"),
        meta: { permission: "BTM_GET_METHOD" },
      },
      {
        path: "btm-map",
        name: "CryptoboxBTMMap",
        component: loadComponent("BTMMapView"),
        meta: { permission: "BTM_GET_METHOD" },
      },
      {
        path: "operations",
        name: "CryptoboxOperations",
        component: loadComponent("OperationsView"),
        meta: { permission: "BTM_TX_GET_METHOD" },
      },
      {
        path: "operations/:id",
        name: "CryptoboxOperationDetail",
        component: loadComponent("OperationDetailView"),
        meta: { permission: "" }, // COMMENT: Sustituir
      },
      {
        path: "sessions",
        name: "CryptoboxSessions",
        component: loadComponent("SessionsView"),
      },
      {
        path: "sessions/:id",
        name: "CryptoboxSessionDetail",
        component: loadComponent("SessionDetailView"),
      },
      {
        path: "btm-versions",
        name: "CryptoboxBTMVersions",
        component: loadComponent("BTMVersionsView"),
      },
    ],
  },
];
