import { MAIN_SERVICE } from "@/service/index.js";

const getSessions = (params) => {
  const route = `/btm_sessions${params}`;
  return MAIN_SERVICE.get(route);
};

const getSession = (id) => {
  const route = `/btm_sessions/${id}`;
  return MAIN_SERVICE.get(route);
};

export {
  getSessions,
  getSession,
};
