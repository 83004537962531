class UsersDTO {
  constructor(Users) {
    // COMMENT Add fullname
    this.Users = Users.map((user) => {
      return {
        id: user.id,
        name: user.name,
        paternalLastName: user.paternalLastName,
        maternalLastName: user.maternalLastName,
        phoneId: user.phoneId,
        kyc: user.kyc,
        devices: user.devices,
        stpSigned: user.stpSigned,
        stpActive: user.stpActive,
        stpExtAccount: user.stpExtAccount,
        stpAccount: user.stpAccount,
        kyc_status: user.kyc_status,
        withdrawalsTx: user.withdrawalsTx,
        sellsTx: user.sellsTx,
        userTx: user.userTx,
        metamapUrlResource: user.metamapUrlResource,
        identityId: user.identityId,
        rfc: user.rfc,
        createdAt: user.createdAt,
      };
    });
  }
}

export default UsersDTO;
