import {
  getPermissions,
  getRoles,
  postRole,
  putRole,
} from "@/service/role.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import RoleDto from "@/dto/ROLE";
import { isAdmin } from "@/helpers/permission-checker";

class RolesError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const operators = {
  state: {
    paginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    roles: [],
    permissions: [],
  },

  mutations: {
    SET_ROLES_ITEMS_PER_PAGE: (state, itemsPerPage) => (state.paginationData.itemsPerPage = itemsPerPage),
    SET_ROLES_PAGINATION_DATA: (state, payload) => {
      state.paginationData.currentPage = payload.currentPage
      state.paginationData.totalItems = payload.totalItems
    },
    SET_ROLES: (state, payload) => (state.roles = payload),
    SET_PERMISSIONS: (state, payload) => (state.permissions = payload),
  },

  actions: {
    async getPermissions({ commit }) {
      try {
        const RES = await getPermissions();
        commit("SET_PERMISSIONS", RES.data.data.map(p => p.id));
      } catch (err) {
        Sentry.captureException(
          new RolesError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },

    async getRoles({ commit }, params) {
      try {
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getRoles(PARAMS_STRING);
        const RolesDTO = RoleDto.get("Roles");
        const ROLES = new RolesDTO(RES.data.data ? RES.data.data : []);
        commit("SET_ROLES_PAGINATION_DATA", RES.data.meta);
        commit("SET_ROLES", ROLES.Roles);
      } catch (err) {
        Sentry.captureException(
          new RolesError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },

    async postRole(_, roleData) {
      try {
        await postRole(roleData);
        return;
      } catch (err) {
        Sentry.captureException(
          new RolesError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },

    async putRole(_, roleData) {
      try {
        await putRole(roleData.id, roleData);
        return;
      } catch (err) {
        Sentry.captureException(
          new RolesError(
            err.code,
            err.response.status,
            err.response.data.error
          )
        );
        throw err.response;
      }
    },
  },

  getters: {
    gPermissions(state) {
      return state.permissions;
    },

    gRoles(state) {
      return state.roles;
    },

    gRolesPaginationData(state) {
      const paginationData = { ...state.paginationData }
      if (!isAdmin()) {
        paginationData.totalItems = paginationData.totalItems - 1
      }
      return paginationData
    },
  },
};

export default operators;
