class WalletsFundsDTO {
  constructor(WalletsFunds, currency) {
    this.WalletsFunds = WalletsFunds.map((fund) => {
      return {
        walletId: fund.WalletId,
        userId: fund.UserId,
        names: fund.Names,
        lastnames: fund.Lastname,
        balance: fund.Balance,
        currency: currency,
        updatedAt: fund.UpdatedAt,
      };
    });
  }
}

export default WalletsFundsDTO;
