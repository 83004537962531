import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import MAIN from "./modules/main.store";
import AUTHENTICATION from "./modules/authentication.store";
import CHART from "./modules/chart.store";
import BTM from "./modules/btm.store";
import BTM_VERSION from "./modules/btm-version.store";
import OPERATION from "./modules/operation.store";
import OPERATORS from "./modules/operators.store";
import ROLES from "./modules/roles.store";
import USERS from "./modules/users.store";
import UTXOS from "./modules/utxos.store";
import GOOGLEMAPS from "./modules/google-maps.store";
import FUNDS from "./modules/funds.store"
import LEGAL from "./modules/legal.store"
import SESSION from "./modules/sessions.store"
import FINANCIAL_CLOSINGS from "./modules/financial-closings.store"
import FUNNEL from "./modules/funnel.store"
import TRANSACTION from "./modules/transaction.store"

export default new Vuex.Store({
  modules: {
    MAIN,
    AUTHENTICATION,
    CHART,
    BTM,
    BTM_VERSION,
    OPERATION,
    OPERATORS,
    ROLES,
    USERS,
    UTXOS,
    GOOGLEMAPS,
    FUNDS,
    LEGAL,
    SESSION,
    FINANCIAL_CLOSINGS,
    FUNNEL,
    TRANSACTION,
  },
});
