import { MAIN_SERVICE } from "@/service/index.js";

const getUsers = (params) => {
  const route = `/cms/users${params}`
  return MAIN_SERVICE.get(route);
};

const getUser = (userId) => {
  const route = `/cms/users/${userId}`
  return MAIN_SERVICE.get(route);
};

const getUsersTransactions = (params) => {
  const route = `/waltxs${params}`
  return MAIN_SERVICE.get(route)
}

export { getUsers, getUser, getUsersTransactions };
