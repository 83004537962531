class RolesDTO {
  constructor(Roles) {
    this.Roles = Roles.map((role) => {
      return {
        id: role.id,
        name: role.name,
        description: role.description,
        permissions: role.permissions,
      };
    });
  }
}

export default RolesDTO;
