import Vue from "vue";
import VueRouter from "vue-router";
/*eslint-disable */
import STORE from "@/store/index.js";
import AUTH from "@/router/modules/authentication.router.js";
import RAYYO from "@/router/modules/rayyo.router.js";
import CRYPTOBOX from "@/router/modules/cryptobox.router.js";
import UI from "@/router/modules/uiLibrary.router.js";
import MONTEBIT from "@/router/modules/montebit.router.js";
Vue.use(VueRouter);

const routes = [
  ...AUTH,
  ...RAYYO,
  ...CRYPTOBOX,
  ...UI,
  ...MONTEBIT,
  {
    path: "/",
    redirect: "/cryptobox/home",
  },
  {
    path: "/404",
    alias: "*",
    name: "NoRoute",
    component: () => import("@/views/NoRoute.vue"),
  },
  {
    path: "/401",
    alias: "/not-authorized",
    name: "NotAuthorized",
    component: () => import("@/views/NotAuthorized.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async(to, from, next) => {
  const LOGGET_OUT_VIEWS = AUTH.map(v => v.name)
  const AUTH_REQUIRED = to.matched.some((record) => record.meta.authRequired);
  const SESSION = localStorage.getItem("op");
  const VIEW_WITH_PERMISSION = to.matched.find(record => record.meta.permission)
  const REQUIRED_PERMISSION = VIEW_WITH_PERMISSION ? VIEW_WITH_PERMISSION.meta.permission : "";
  let operatorPermissions = STORE.getters.gOperatorPermissions

  if (SESSION && operatorPermissions.length === 0) {
    await STORE.dispatch("getOperatorSession")
    operatorPermissions = STORE.getters.gOperatorPermissions
  }

  if (LOGGET_OUT_VIEWS.includes(to.name) && SESSION) {
    next({ name: "CryptoboxHome" });
  } else if (AUTH_REQUIRED && !SESSION) {
    next({ name: "LoginView" });
  } else if (!REQUIRED_PERMISSION) {
    next()
  } else {
    if (operatorPermissions.length > 0) {
      if (operatorPermissions.includes(REQUIRED_PERMISSION)) {
        next();
      } else {
        next({ name: "CryptoboxHome" });
      }
    }
    next()
  }
});

export default router;
