class BTMDTO {
  constructor(UTXOs) {
    this.UTXOs = UTXOs.map((utxoData) => {
      return {
        address: utxoData.address,
        amount: utxoData.amount,
        createdAt: utxoData.created_at,
        outpoint: utxoData.outpoint,
        confirmations: utxoData.confirmations,
        isDust: utxoData.is_dust,
      };
    });
  }
}

export default BTMDTO;
