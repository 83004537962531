import {
  getBTMVersions,
  getBTMVersion,
  postBTMVersion,
  putBTMVersion,
  deleteBTMVersion,
} from "@/service/btm-version.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import BTMDto from "@/dto/BTM";

class BTMVersionError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const btm_version = {
  state: {
    paginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    BTMVersions: [],
  },

  mutations: {
    SET_BTM_VERSIONS_PAGINATION_DATA: (state, payload) => (state.paginationData = payload),
    SET_BTM_VERSIONS: (state, payload) => (state.BTMVersions = payload),
  },

  actions: {
    async getBTMVersions({ commit }, params) {
      try {
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getBTMVersions(PARAMS_STRING);
        const DATA = RES.data;

        const BTMVersionDTO = BTMDto.get("BTMVersion");
        const PARSED_DATA = new BTMVersionDTO(DATA.data ? DATA.data : []);
        const PARSED_BTM_VERSIONS = PARSED_DATA.BTMVersions.reverse()
        commit("SET_BTM_VERSIONS_PAGINATION_DATA", DATA.meta);
        commit("SET_BTM_VERSIONS", PARSED_BTM_VERSIONS);
      } catch (err) {
        Sentry.captureException(
          new BTMVersionError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getBTMVersion(_, id) {
      try {
        const RES = await getBTMVersion(id)
        return RES.data.data
      } catch (err) {
        Sentry.captureException(
          new BTMVersionError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async postBTMVersion(_, body) {
      try {
        await postBTMVersion(body)
      } catch (err) {
        Sentry.captureException(
          new BTMVersionError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async putBTMVersion(_, payload) {
      try {
        await putBTMVersion(payload.id, payload.body)
      } catch (err) {
        Sentry.captureException(
          new BTMVersionError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async deleteBTMVersion(_, id) {
      try {
        await deleteBTMVersion(id);
      } catch (err) {
        Sentry.captureException(
          new BTMVersionError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },

  getters: {
    gBTMVersions(state) {
      return state.BTMVersions;
    },

    gBTMVersionPaginationData(state) {
      return state.paginationData;
    },
  },
};

export default btm_version;
