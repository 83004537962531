<template>
  <div id="app" class="space">
    <router-view v-if="!loading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const SESSION = localStorage.getItem("op");

export default {
  name: "app",

  data() {
    return {
      loading: false
    }
  },

  mounted() {
    if (SESSION) {
      this.loading = true
      const PROMISES = [this.getOperatorSession(), this.setGoogleMapsApi()]
      Promise.all(PROMISES).then(() => this.loading = false).catch(() => {
        this.$store.dispatch("logOut")
        this.$router.push({ name: "LoginView" });
        location.reload()
      })
    }
  },

  methods: {
    async getOperatorSession() {
      await this.$store.dispatch("getOperatorSession", this.gTokenData.id);
    },

    async setGoogleMapsApi() {
      await this.$store.dispatch("setGoogleMapsApi")
    },
  },

  computed: {
    ...mapGetters(["gTokenData"]),
  },
};
</script>
