import {
  getBTMs,
  getBTM,
  postBTM,
  putBTM,
  getBTMZones,
  getBTMBalances,
  getBTMVideos,
  getBTMLogs,
  postBTMRegistrationCode,
  putBTMBulkActions,
  getBTMsDailyClosures,
} from "@/service/btm.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import BTMDto from "@/dto/BTM";

class BTMError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

// COMMENT: Se puede mover al DTO
const parseWithdrawalToMultiplier = (amount) => 1 - amount / 100;
const parseBuyToMultiplier = (amount) => 1 + amount / 100;
const parseWithdrawalToPercentage = (amount) => Math.round((1 - amount) * 100);
const parseBuyToPercentage = (amount) => Math.round((amount - 1) * 100);

const btm = {
  state: {
    paginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    BTMs: [],
    operationsPaginationData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
    },
    operations: [],
    BTMZones: [],
  },

  mutations: {
    SET_BTMS_PAGINATION_DATA: (state, payload) => (state.paginationData = payload),
    SET_BTMS: (state, payload) => (state.BTMs = payload),
    SET_BTM_ZONES: (state, payload) => (state.BTMZones = payload),
  },

  actions: {
    async getBTMs({ commit }, params) {
      try {
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getBTMs(PARAMS_STRING);
        const DATA = RES.data;

        const BTMDTO = BTMDto.get("BTM");
        const PARSED_DATA = new BTMDTO(DATA.data ? DATA.data : []);
        const PARSED_BTMS = PARSED_DATA.BTMs.map((btm) => {
          btm.withdrawMultiplier = parseWithdrawalToPercentage(btm.withdrawMultiplier);
          btm.buyMultiplier = parseBuyToPercentage(btm.buyMultiplier);
          return btm;
        });
        commit("SET_BTMS_PAGINATION_DATA", DATA.meta);
        commit("SET_BTMS", PARSED_BTMS);
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getAllBTMs(_, params) {
      try {
        params.pagination = false;
        const PARAMS_STRING = paramsBuilder(params);
        const RES = await getBTMs(PARAMS_STRING);
        const DATA = RES.data.data;

        const BTMDTO = BTMDto.get("BTM");
        const PARSED_DATA = new BTMDTO(DATA ? DATA : []);
        const PARSED_BTMS = PARSED_DATA.BTMs.map((btm) => {
          btm.withdrawMultiplier = parseWithdrawalToPercentage(btm.withdrawMultiplier);
          btm.buyMultiplier = parseBuyToPercentage(btm.buyMultiplier);
          return btm;
        });
        return PARSED_BTMS;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getBTM(_, id) {
      try {
        const RES = await getBTM(id);
        const BTM = RES.data;
        BTM.data.withdrawMultiplier = parseWithdrawalToPercentage(BTM.data.withdrawMultiplier);
        BTM.data.buyMultiplier = parseBuyToPercentage(BTM.data.buyMultiplier);
        return BTM;
      } catch (err) {
        console.log(err);
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async postBTM(_, btmData) {
      try {
        btmData.withdrawMultiplier = parseWithdrawalToMultiplier(btmData.withdrawMultiplier);
        btmData.buyMultiplier = parseBuyToMultiplier(btmData.buyMultiplier);
        const RES = await postBTM(btmData);
        return RES.data.data;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async putBTM(_, btmData) {
      try {
        if (btmData.withdrawMultiplier) {
          btmData.withdrawMultiplier = parseWithdrawalToMultiplier(btmData.withdrawMultiplier);
        }
        if (btmData.buyMultiplier) {
          btmData.buyMultiplier = parseBuyToMultiplier(btmData.buyMultiplier);
        }
        await putBTM(btmData.id, btmData);
        return;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getBTMZones({ commit }) {
      try {
        const DATA = await getBTMZones();
        commit("SET_BTM_ZONES", DATA.data.data);
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getBTMBalances(_, payload) {
      try {
        let paramsString = paramsBuilder(payload.params);
        const RES = await getBTMBalances(paramsString);
        return RES.data.data;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async postBTMRegistrationCode(_, payload) {
      try {
        const DATA = await postBTMRegistrationCode(payload);
        return DATA.data.data;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async putBTMBulkbuyAndSellPercentage(_, payload) {
      try {
        payload.withdrawMultiplier = parseWithdrawalToMultiplier(payload.withdrawMultiplier);
        payload.buyMultiplier = parseBuyToMultiplier(payload.buyMultiplier);
        const DATA = await putBTMBulkActions(payload);
        return DATA.data.data;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async putBulkBTM(_, payload) {
      try {
        const DATA = await putBTMBulkActions(payload);
        return DATA.data.data;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
    
    async getBTMVideos(_, payload) {
      try {
        let paramsString = payload.isSingleVideo
          ? payload.idVideo
          : paramsBuilder(payload.params);
        const RES = await getBTMVideos(paramsString);
        return RES.data.data;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getBTMLogs(_, params) {
      try {
        let paramsString = params.logId ? params.logId : paramsBuilder(params)
        const RES = await getBTMLogs(paramsString);
        return RES.data.data;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },

    async getBTMsDailyClosures(_, payload) {
      try {
        let paramsString = paramsBuilder(payload.params);
        const RES = await getBTMsDailyClosures(paramsString);
        const BTMDailyClosuresDTO = BTMDto.get("BTMDailyClosures");
        const parsedData = new BTMDailyClosuresDTO(RES.data.data ? RES.data.data : []);
        return parsedData.BTMsDailyClosures;
      } catch (err) {
        Sentry.captureException(
          new BTMError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },

  getters: {
    gBTMs(state) {
      return state.BTMs;
    },

    gBTMPaginationData(state) {
      return state.paginationData;
    },

    gBTMZones(state) {
      return state.BTMZones;

      // Comment to trigger new deploy
      // const ACCEPTED_ZONE_IDS = [
      //   // 1693, 1694, 1695, 1696, 1697, 1699, 1703, 1704, 1723, 1755, 1756, 1757,
      //   // 1758, 1759, 1760, 1778, 1773,
      //   "CDMX Centro",
      //   "CDMX Norte",
      //   "CDMX Oriente",
      //   "CDMX Poniente",
      //   "CDMX Sur",
      //   "Chalco - Ixtapaluca",
      //   "Chimalhuacan",
      //   "Coacalco",
      //   "Guadalajara",
      //   "Monterrey Cumbres",
      //   "Monterrey Centro",
      //   "Monterrey Norte",
      //   "Monterrey Oriente",
      //   "Monterrey Sendero",
      //   "Monterrey Sur",
      //   "Perinorte - Cuautitlán",
      //   "Querétaro",
      // ];
      // const FILTERED_ZONES = state.BTMZones.filter((z) => {
      //   return ACCEPTED_ZONE_IDS.includes(z.description);
      // });
      // return FILTERED_ZONES;
    },
  },
};

export default btm;
