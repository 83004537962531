class OperatorsDTO {
  constructor(Operators) {
    this.Operators = Operators.map((operatorData) => {
      return {
        loginName: operatorData.loginName,
        names: operatorData.names,
        lastNames: operatorData.lastNames,
        dob: operatorData.dob,
        lastLogin: operatorData.lastLogin,
        authorized: operatorData.authorized,
        roles: operatorData.roles,
        profileImageUrl: operatorData.profileImageUrl,
        createdAt: operatorData.createdAt,
        updatedAt: operatorData.updatedAt,
        department: "Área principal", // COMMENT: Computar este valor en front segun los roles de id que tenga
      };
    });
  }
}

export default OperatorsDTO;
