class SessionDTO {
  constructor(sessions) {
    this.Sessions = sessions.map((session) => {
      return {
        id: session.id,
        type: session.type,
        btmId: session.btmId,
        btmTxId: session.btmTxId,
        data: session.data,
        videoId: session.videoId,
        createdAt: session.createdAt,
        updatedAt: session.updatedAt,
      };
    });
  }
}

export default SessionDTO;
