import { MAIN_SERVICE } from "@/service/index.js";

const getBTMEarningsChartData = (params) => {
  const route = `/btms/earnings${params}`
  return MAIN_SERVICE.get(route)
}

const getFundsChartData = (params) => {
  const route = `/reports/balances${params}`
  return MAIN_SERVICE.get(route)
}

// COMMMENT: This endpoint appear to be deleted
const getNewUsersChartData = params => {
  const route = `/users/stats${params}`
  return MAIN_SERVICE.get(route)
}

export { getBTMEarningsChartData, getFundsChartData, getNewUsersChartData }