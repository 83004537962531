const paramsBuilder = (paramsObject) => {
  const paramsArray = [];

  for (let key in paramsObject) {
    const value = paramsObject[key];
    const formattedValue = Array.isArray(value) ? value.join(",") : value;
    paramsArray.push(
      `${key}${Array.isArray(value) ? "[]" : ""}=${formattedValue}`
    );
  }

  const paramsString = paramsArray.join("&");
  return paramsString ? `?${paramsString}` : "";
};

export default paramsBuilder;
