const loadComponent = (component) => {
  return () => import(`@/views/Authentication/${component}.vue`);
};

export default [
  {
    path: "/login",
    name: "LoginView",
    component: loadComponent("LoginView"),
  },
  // {
  //   path: "/reset-password",
  //   name: "ResetPasswordView",
  //   component: loadComponent("ResetPasswordView"),
  // },
  // {
  //   path: "/request-password",
  //   name: "RequestPasswordReset",
  //   component: loadComponent("RequestPasswordResetView"),
  // },
  // {
  //   path: "/register",
  //   name: "RegisterView",
  //   component: loadComponent("RegisterView"),
  // },
  {
    path: "/set-password",
    name: "PasswordAssignmentView",
    component: loadComponent("PasswordAssignmentView"),
  },
];
