import { MAIN_SERVICE } from "@/service/index.js";

const getWalletBalances = (currency) => {
  const route = `/cms/walletbalance/${currency}`;
  return MAIN_SERVICE.get(route);
}

// COMMENT: TO BE DELETED
const getFunds = (params) => {
  const route = `/cms/waltxs${params}`;
  return MAIN_SERVICE.get(route);
};

const getWalletsFunds = (currency, params) => {
  const route = `/cms/walletfunds/${currency}${params}`;
  return MAIN_SERVICE.get(route);
};

const getTotalWalletFunds = (params) => {
  const route = `/reports/balances/details${params}`;
  return MAIN_SERVICE.get(route);
};

// To send BTC to a different account.
const withdrawBTC = (body) => {
  const route = `/cms/withdrawbtc`;
  return MAIN_SERVICE.post(route, body);
};

const withdrawMXN = (body) => {
  const route = `/cms/withdrawmxn`;
  return MAIN_SERVICE.post(route, body);
}

const transfer = (body) => {
  const route = `/cms/transfer`;
  return MAIN_SERVICE.post(route, body);
}

const createNewAddress = () => {
  const route = `/cms/newaddress`;
  return MAIN_SERVICE.get(route);
};

const getExchangeRates = () => {
  const route = `/exchange_rates`;
  return MAIN_SERVICE.get(route);
};

export { getWalletBalances, getFunds, getWalletsFunds, getTotalWalletFunds, withdrawBTC, withdrawMXN, transfer, createNewAddress, getExchangeRates };
