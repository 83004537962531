import { getFunnelData } from "@/service/funnel.api";
import * as Sentry from "@sentry/vue";

class FunnelError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const funnel = {
  state: {
    funnelData: [],
  },

  mutations: {
    SET_FUNNEL_DATA: (state, payload) => (state.funnelData = payload),
  },

  actions: {
    async getFunnelData({ commit }) {
      try {
        const RES = await getFunnelData();
        commit("SET_FUNNEL_DATA", RES.data.data);
      } catch (err) {
        Sentry.captureException(
          new FunnelError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },

  getters: {
    gStages(state) {
      let stages = [
        {
          stage: "pending",
          title: "Pendiente",
          amount: 0,
        },
        {
          stage: "termsAndConditionsAccepted",
          title: "Firma de Legales (T&C y AdP)",
          amount: 0,
        },
        {
          stage: "rejected",
          title: "Rechazado",
          amount: 0,
        },
        {
          stage: "onProcess",
          title: "En proceso",
          amount: 0,
        },
        {
          stage: "reviewNeeded",
          title: "Revisión necesaria",
          amount: 0,
        },
        {
          stage: "postponed",
          title: "Pospuesto",
          amount: 0,
        },
        {
          stage: "duplicated",
          title: "Duplicado",
          amount: 0,
        },
        {
          stage: "completedMetamap",
          title: "Finalizó MetaMap",
          amount: 0,
        },
      ]

      stages = stages.map(stage => {
        const matchingFunnelStage =  state.funnelData.find(funnelData => funnelData.kyc_status === stage.stage)
        if (matchingFunnelStage) {
          stage.amount = matchingFunnelStage.count
        }
        return stage
      })
      return stages
    },
  },
};

export default funnel;
