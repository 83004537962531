const loadComponent = (component) => {
  return () => import(`@/views/UiLibrary/${component}.vue`);
};

export default [
  {
    path: "/ui",
    name: "UiLibraryView",
    component: loadComponent("UiLibraryView"),
    redirect: "/ui/avatar",
    children: [
      {
        path: "avatar",
        name: "AvatarView",
        component: loadComponent("AvatarView"),
      },
      {
        path: "label",
        name: "LabelView",
        component: loadComponent("LabelView"),
      },
      {
        path: "button",
        name: "ButtonView",
        component: loadComponent("ButtonView"),
      },
      {
        path: "inputfield",
        name: "InputFieldView",
        component: loadComponent("InputFieldView"),
      },
      {
        path: "dropdown",
        name: "DropdownView",
        component: loadComponent("DropdownView"),
      },
      {
        path: "grid",
        name: "GridView",
        component: loadComponent("GridView"),
      },
      {
        path: "tile",
        name: "TileView",
        component: loadComponent("TileView"),
      },
      {
        path: "sidebar",
        name: "SidebarView",
        component: loadComponent("SidebarView"),
      },
      {
        path: "collection",
        name: "CollectionView",
        component: loadComponent("CollectionView"),
      },
      {
        path: "box",
        name: "BoxView",
        component: loadComponent("BoxView"),
      },
      {
        path: "icon",
        name: "IconView",
        component: loadComponent("IconView"),
      },
      {
        path: "table",
        name: "TableView",
        component: loadComponent("TableView"),
      },
      {
        path: "datatable",
        name: "DataTableView",
        component: loadComponent("DataTableView"),
      },
      {
        path: "toaster",
        name: "ToasterView",
        component: loadComponent("ToasterView"),
      },
      {
        path: "chart",
        name: "ChartView",
        component: loadComponent("ChartView"),
      },
      {
        path: "progress-bar",
        name: "ProgressBarView",
        component: loadComponent("ProgressBarView"),
      },
      {
        path: "filterfinder",
        name: "FilterFinderView",
        component: loadComponent("FilterFinderView"),
      },
      {
        path: "select",
        name: "SelectView",
        component: loadComponent("SelectView"),
      },
      {
        path: "downloader",
        name: "DownloaderView",
        component: loadComponent("DownloaderView"),
      },
      {
        path: "stockchange",
        name: "StockChangeView",
        component: loadComponent("StockChangeView"),
      },
      {
        path: "paginator",
        name: "PaginatorView",
        component: loadComponent("PaginatorView"),
      },
      {
        path: "switch",
        name: "SwitchView",
        component: loadComponent("SwitchView"),
      },
      {
        path: "modal",
        name: "ModalView",
        component: loadComponent("ModalView"),
      },
      {
        path: "map",
        name: "MapView",
        component: loadComponent("MapView"),
      },
      {
        path: "checkbox",
        name: "CheckboxView",
        component: loadComponent("CheckboxView"),
      },
      {
        path: "card",
        name: "CardView",
        component: loadComponent("CardView"),
      },
      {
        path: "expandable",
        name: "ExpandableView",
        component: loadComponent("ExpandableView"),
      },
      {
        path: "geocoder",
        name: "GeocoderView",
        component: loadComponent("GeocoderView"),
      },
      {
        path: "image-loader",
        name: "ImageLoaderView",
        component: loadComponent("ImageLoaderView"),
      },
      {
        path: "datepicker",
        name: "DatepickerView",
        component: loadComponent("DatepickerView"),
      },
      {
        path: "menu",
        name: "MenuView",
        component: loadComponent("MenuView"),
      },
      {
        path: "divider",
        name: "DividerView",
        component: loadComponent("DividerView"),
      },
      {
        path: "daterange",
        name: "DaterangeView",
        component: loadComponent("DaterangeView"),
      },
      {
        path: "caption",
        name: "CaptionView",
        component: loadComponent("CaptionView"),
      },
      {
        path: "badge",
        name: "BadgeView",
        component: loadComponent("BadgeView"),
      },
      {
        path: "tooltip",
        name: "TooltipView",
        component: loadComponent("TooltipView"),
      },
      {
        path: "textarea",
        name: "TextAreaView",
        component: loadComponent("TextAreaView"),
      },
      {
        path: "form",
        name: "FormView",
        component: loadComponent("FormView"),
      },
      {
        path: "filecard",
        name: "FileCardView",
        component: loadComponent("FileCardView"),
      },
      {
        path: "radio-group",
        name: "RadioView",
        component: loadComponent("RadioView"),
      },
      {
        path: "breadcrumbs",
        name: "BreadcrumbsView",
        component: loadComponent("BreadcrumbsView"),
      },
      {
        path: "autocomplete",
        name: "AutocompleteView",
        component: loadComponent("AutocompleteView"),
      },
    ],
  },
];
