class BTMDailyClosuresDTO {
  constructor(BTMsDailyClosures) {
    this.BTMsDailyClosures = BTMsDailyClosures.map((btmDailyClosure, index) => {
      return {
        No: index + 1,
        id: btmDailyClosure.id,
        terminalNo: btmDailyClosure.terminalNo,
        cutoffDate: btmDailyClosure.cutoffDate,
        lastOperationDate: btmDailyClosure.lastOperationDate,
        notesInsertedOperator: btmDailyClosure.notesInsertedOperator,
        amountInsertedOperator: btmDailyClosure.amountInsertedOperator,
        insertedUsers: btmDailyClosure.insertedUsers,
        withdrawnUsers: btmDailyClosure.withdrawnUsers,
        totalWithdrawnOperator: btmDailyClosure.totalWithdrawnOperator,
        recyclerBalance: btmDailyClosure.recyclerBalance,
        lastOperatorNo: btmDailyClosure.lastOperatorNo,
        lastOperatorName: btmDailyClosure.lastOperatorName,
        lastOperatorRecordDate: btmDailyClosure.lastOperatorRecordDate,
        numberNotesTwenty: btmDailyClosure.notes[0].denomination_count,
        valueNotesTwenty: btmDailyClosure.notes[0].denomination_total,
        numberNotesFifty: btmDailyClosure.notes[1].denomination_count,
        valueNotesFifty: btmDailyClosure.notes[1].denomination_total,
        numberNotesOneHundred: btmDailyClosure.notes[2].denomination_count,
        valueNotesOneHundred: btmDailyClosure.notes[2].denomination_total,
        numberNotesTwoHundred: btmDailyClosure.notes[3].denomination_count,
        valueNotesTwoHundred: btmDailyClosure.notes[3].denomination_total,
        numberNotesFiveHundred: btmDailyClosure.notes[4].denomination_count,
        valueNotesFiveHundred: btmDailyClosure.notes[4].denomination_total,
        numberNotesOneThousand: btmDailyClosure.notes[5].denomination_count,
        valueNotesOneThousand: btmDailyClosure.notes[5].denomination_total,
      };
    });
  }
}

export default BTMDailyClosuresDTO;
