import { MAIN_SERVICE } from "@/service/index.js";

const getFinancialClosings = (type, params) => {
  const route = `/financial-closing/${type}${params}`
  return MAIN_SERVICE.get(route)
}

const downloadFinancialClosings = (type, params) => {
  const route = `/financial-closing/${type}/download${params}`
  return MAIN_SERVICE.get(route, { responseType: 'blob' })
}

export { getFinancialClosings, downloadFinancialClosings };
