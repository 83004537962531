class TransactionsDTO {
  constructor(Transactions) {
    this.Transactions = Transactions.map((transaction) => {
      return {
        id: transaction.id,
        walletId: transaction.walletId,
        userId: transaction.userId,
        txType: transaction.txType,
        amount: transaction.amount,
        prevBalance: transaction.prevBalance,
        newBalance: transaction.newBalance,
        refer: transaction.refer,
        notificationId: transaction.notificationId,
        memo: transaction.memo,
        sourceTx: transaction.sourceTx,
        sourceTxTable: transaction.sourceTxTable,
        createdAt: transaction.createdAt,
        txDetails: transaction.txDetails,
        status: transaction.status,
        userFullName: transaction.userFullName,
        phoneId: transaction.phoneId,
        currency: transaction.currency,
      };
    });
  }
}

export default TransactionsDTO;
