import { satoshisToPesos } from "@/helpers/currency-formatters.js"

const coinSpread = (btcPrice, marketPrice) => Math.abs(btcPrice - marketPrice)

const earnings = (btcPrice, marketPrice, btcAmount, providerFee) => Math.abs(coinSpread(btcPrice, marketPrice) * (btcAmount / 100000000)).toFixed(2) - satoshisToPesos(marketPrice, Math.abs(providerFee)) * 100

const commission = (btcPrice, marketPrice) => (coinSpread(btcPrice, marketPrice) / marketPrice * 100).toFixed(2)

const withdrawalStatus = (btcAmount, finalizedAt) => {
  if (btcAmount < 0) {
    return null
  } else {
    return finalizedAt ? "REDEEMED" : "NOT_REDEEMED"
  }
}

class OperationsDTO {
  constructor(Operations) {
    this.Operations = Operations.map((operation) => {
      return {
        id: operation.id,
        startedAt: operation.startedAt,
        btmId: operation.btmId,
        smsId: operation.smsId,
        mxnAmount: operation.mxnAmount,
        btcAmount: operation.btcAmount,
        fee: operation.fee,
        providerFee: operation.providerFee,
        btcPrice: operation.btcPrice,
        marketPrice: operation.marketPrice,
        address: operation.address,
        txId: operation.txId,
        status: operation.status,
        statusDetail: operation.statusDetail,
        finalizedAt: operation.finalizedAt,
        userId: operation.userId,
        appFiles: operation.appFiles,
        coinSpread: coinSpread(operation.btcPrice, operation.marketPrice),
        earnings: earnings(operation.btcPrice, operation.marketPrice, operation.btcAmount, operation.providerFee),
        commission: commission(operation.btcPrice, operation.marketPrice),
        type: operation.btcAmount < 0 ? "SALE" : "WITHDRAWAL",
        withdrawalStatus: withdrawalStatus(operation.btcAmount, operation.finalizedAt)
      };
    });
  }
}

export default OperationsDTO;
