import { getFunds } from "@/service/funds.api";
import paramsBuilder from "@/helpers/params-builder";
import * as Sentry from "@sentry/vue";
import TransactionsDto from "@/dto/TRANSACTION";

class TransactionsError extends Error {
  constructor(name, errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = `${name} (${errorCode}): ${message}`;
  }
}

const funds = {
  state: {
    paginationData: {
      page: 1,
      itemsPerPage: 5,
      totalItems: 0,
    },
    transactions: [],
  },

  mutations: {
    SET_TRANSACTIONS: (state, payload) => (state.transactions = payload),
    SET_TRANSACTIONS_PAGINATION_DATA: (state, payload) =>
      (state.paginationData = payload),
  },

  actions: {
    async getTransactions({ commit }, params) {
      try {
        const paramsString = paramsBuilder(params);
        const DATA = await getFunds(paramsString);
        const { meta } = DATA.data;
        const DTO = TransactionsDto.get("Transactions");
        const parsedData = new DTO(DATA.data.data ? DATA.data.data : []);
        commit("SET_TRANSACTIONS", parsedData.Transactions);
        commit("SET_TRANSACTIONS_PAGINATION_DATA", {
          page: meta.currentPage,
          itemsPerPage: meta.itemsPerPage,
          totalItems: meta.totalItems,
        });
      } catch (err) {
        Sentry.captureException(
          new TransactionsError(err.code, err.response.status, err.response.data.error)
        );
        throw err.response;
      }
    },
  },
  
  getters: {
    gTransactionsPaginationData(state) {
      return state.paginationData;
    },

    gTransactions(state) {
      return state.transactions;
    }
  },
};

export default funds;
