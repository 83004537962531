import store from '../store'

const hasPermision = (permissionName) => {
  return store.getters.gOperatorPermissions.includes(permissionName)
}

export default hasPermision

export const isAdmin = () => {
  return store.getters.gOperatorData.roles.some(role => role.name === "Admin")
}
