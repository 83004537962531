class BTMVersionDTO {
  constructor(BTMVersions) {
    this.BTMVersions = BTMVersions.map((version) => {
      return {
        id: version.id,
        version: version.version,
        changeLog: version.changeLog,
        updatedAt: version.updatedAt,
        createdAt: version.createdAt,
      };
    });
  }
}

export default BTMVersionDTO;