const btcAmountComputer = (fund) => {
  if (fund.txType === "C") {
    return fund.txDetails.btcAmount
  } else {
    return fund.amount
  }
}


class FundsDTO {
  constructor(Funds) {
    this.Funds = Funds.map((fund) => {
      return {
        createdAt: fund.createdAt,
        id: fund.id,
        userId: fund.userId,
        blockchainId: fund.txDetails?.txID,
        btcAddress: fund.txDetails?.btcAddress,
        marketPrice: fund.txDetails?.marketPrice,
        mxnAmount: fund.txDetails?.mxnAmount,
        sourceTxTable: fund.sourceTxTable,
        txType: fund.txType,
        btcAmount: btcAmountComputer(fund),
      };
    });
  }
}

export default FundsDTO;
