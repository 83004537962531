const main = {
  state: {
    sidebarIsMini: false,
  },

  mutations: {
    TOGGLE_SIDEBAR_IS_MINI: state => (state.sidebarIsMini = !state.sidebarIsMini),
  },

  getters: {
    gSidebarIsMini(state) {
      return state.sidebarIsMini;
    },
  },
};

export default main;
